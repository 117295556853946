import { useState, useEffect } from 'react';

export const useDebounce = (value, delay, socket, roomID) => {
  const [dev, setdev] = useState(value);
  useEffect(() => {
    const timeoudId = setTimeout(() => {
      setdev(value);
      socket.emit('getAllTeamsName', roomID, value);
    }, delay);
    return () => {
      clearTimeout(timeoudId);
    };
  }, [value, delay, socket, roomID]);
  return dev;
};
