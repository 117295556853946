import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter as Router } from 'react-router-dom';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { ChakraProvider } from "@chakra-ui/react"
import './i18n'; // Import i18n

const link = createHttpLink({
  uri: `${process.env.REACT_APP_HTTP_S}://${process.env.REACT_APP_IP_ADDRESS}/graphql`,
  credentials: "include"
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Router>
    <ChakraProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ChakraProvider>
  </Router>
);