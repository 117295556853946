import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { ShipSlice } from './shipSlice.jsx';
import './index.css';
export function Ships({ setShipSelected, shipPlaces, setVH, t }) {
  const handleVS = V => {
    setVH(V);
    let v = document.getElementById('vertical');
    let h = document.getElementById('horizontal');
    if (V === 'vertical') {
      v.classList.toggle('vs-selected', true);
      h.classList.toggle('vs-selected', false);
    }
    if (V === 'horizontal') {
      v.classList.toggle('vs-selected', false);
      h.classList.toggle('vs-selected', true);
    }
  };
  const handleShip = shipN => {
    setShipSelected(shipN);
    [2, 3, 4, 5].map(number => {
      let ship = document.getElementById(`ship${number}`);
      if (number === shipN) {
        ship.classList.toggle('ship-select', true);
      } else {
        ship.classList.toggle('ship-select', false);
      }
      return null;
    });
  };
  shipPlaces.map(({ shipSize }) => {
    return (document.getElementById(`ship${shipSize}`).style.display = 'none');
  });
  return (
    <Box className="ships">
      <Flex direction="column">
        <Flex direction="row">
          <ShipSlice slice={5} handleShip={handleShip} />
          <ShipSlice slice={2} handleShip={handleShip} />
          <Button
            className="vs"
            id="vertical"
            onClick={() => handleVS('vertical')}
          >
            {t("V")}
          </Button>
        </Flex>
        <Flex direction="row">
          <ShipSlice slice={4} handleShip={handleShip} />
          <ShipSlice slice={3} handleShip={handleShip} />
          <Button
            className="vs vs-selected"
            id="horizontal"
            onClick={() => handleVS('horizontal')}
          >
            {t("H")}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
