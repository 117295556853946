import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
export function AdminConfirmation({ socket, roomID, type, t }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [teamName, setTeamName] = useState('');
  const [Place, setPlace] = useState(0);
  const [rocket, setRocket] = useState('Single');
  const handleAdminClick = () => {
    socket.emit('PlayerFire', roomID, Place, teamName);
    socket.emit('getGameInfo', roomID);
    onClose();
  };
  const handleAdminClickCancel = () => {
    socket.emit('AdminRefusedStrike', roomID, teamName, Place);
    onClose();
  };
  useEffect(() => {
    socket.on('PlayerAskPermission', (teamName, place, rocketP) => {
      setTeamName(teamName);
      setPlace(place);
      setRocket(rocketP);
      onOpen();
    });
  }, [onOpen, socket]);
  let clickedString = String(Place);
  if (type === 'Admin')
    return (
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="model-text">
            {teamName} {t('preparing_Strike')} {clickedString} {t('With')}{' '}
            {rocket}.{' '}
          </ModalHeader>
          <ModalFooter className="model-footer">
            <Button
              colorScheme="blue"
              className="model-text model-button"
              onClick={handleAdminClick}
            >
              {t("Confirm")}
            </Button>
            <Button
              colorScheme="red"
              className="model-text model-button"
              onClick={handleAdminClickCancel}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
}
