import React from 'react';
import { Login, Signup } from '../index';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';
export function StartingModal({ isOpen, onClose, history }) {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);
  const porps = { setTabIndex, history };
  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create your account</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Tabs
            isFitted
            variant="enclosed"
            onChange={index => setTabIndex(index)}
            index={tabIndex}
          >
            <TabList mb="1em">
              <Tab>Create a new account</Tab>
              <Tab>Sign in</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Signup {...porps} />
              </TabPanel>
              <TabPanel>
                <Login {...porps} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
