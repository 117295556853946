import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BattleGrid } from '../../components';
import {
  Box,
  Flex,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Button,
  Select,
} from '@chakra-ui/react';
import './index.css';
import { useParams } from 'react-router-dom';
import { socket } from '../../socket';
import rocket_png from '../../assets/rocket.png';
import {
  getButtonPositions,
  hittedNames,
  Play,
  shuffleArray,
} from '../../utils/functions';
import rocket_launch from '../../sounds/rocket-launch.mp3';
import rocket_hit from '../../sounds/one.mp3';
import rocket_miss from '../../sounds/water.mp3';
import ship_fall from '../../sounds/fall.mp3';
import { MdLanguage } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

export function GameWatchPage({ t }) {
  const { roomID } = useParams();
  const userIdURL = roomID.slice(0, 4);
  const gridSize = roomID.slice(4, 7);
  const buttonsRef = useRef([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const Win = useDisclosure();
  const [animate, setAnimate] = useState(false);
  const [turn, setTurn] = useState('');
  const [rocketPosition, setRocketPosition] = useState([]);
  const Watching = useRef(false);
  const rocketFire = useRef([5]);
  const WinTeam = useRef('');
  const text = useRef('');
  const props = {
    userIdURL,
    gridSize,
    socket,
    buttonsRef,
    type: 'Watch',
  };
  let index = 0;
  const animateRocketToButton = useCallback(
    async (buttonId, rocketId) => {
      const targetButton = getButtonPositions(buttonsRef).find(
        button => Number(button.id) === buttonId
      );
      if (!targetButton) return;
      setRocketPosition(prev => {
        return [
          ...prev,
          {
            index: index++,
            id: rocketId,
            top: targetButton.top,
            left: targetButton.left,
          },
        ];
      });
      setAnimate(true);
      if (Watching) Play(rocket_launch);
      setTimeout(() => setAnimate(false), 5000);
    },
    [buttonsRef]
  );
  useEffect(() => {
    [...Array(Number(gridSize))].map((e, i) => {
      const Button = document.getElementById(`GridNumber-${i + 1}`);
      buttonsRef.current[i] = Button;
    });
  }, []);
  useEffect(() => {
    socket.emit('connectWatch', roomID);
    socket.on('TurnChanged', name => {
      setTurn(name);
    });
    socket.on('RocketUpdated', (r, O) => {
      let rockets = [];
      switch (r) {
        case 'Single':
          rockets.push(5);
          break;
        case 'Triple':
          if (O === 'Horizontal') rockets.push(4, 5, 6);
          else if (O === 'Vertical') rockets.push(2, 5, 8);
          break;
        case 'X':
          rockets.push(1, 3, 5, 7, 9);
          break;
        case 'BigBang':
          rockets.push(1, 2, 3, 4, 5, 6, 7, 8, 9);
          break;
        default:
          break;
      }
      for (let i = 0; i < 9; i++) {
        let rocket = document.getElementById(`rocket${i + 1}`);
        rocket.style.opacity = 0;
      }
      rockets.forEach(n => {
        let rocket = document.getElementById(`rocket${n}`);
        rocket.style.opacity = 1;
      });
      rocketFire.current = rockets;
    });
    socket.on('AfterHit', async info => {
      let { strike, miss, fall, place, hitted, player } = info;
      const events = Array.from({ length: strike + miss }, (_, index) =>
        index < strike ? 'hit' : 'miss'
      );
      const shuffledEvents = shuffleArray(events);
      setTimeout(() => {
        processEvent(0, 0);
        const teamNames = hittedNames(hitted);

        if (miss !== 0 && strike === 0) {
          text.current = `<strong>${player.teaminfo.name}</strong>&nbsp;${t(
            'LunchRocket'
          )}&nbsp;<strong>${place}</strong>,<br>${t('MissRocket')}`;
        } else if (strike === 1) {
          text.current = `<strong>${player.teaminfo.name}</strong>&nbsp;${t(
            'LunchRocket_Hit_1'
          )}&nbsp;<strong>${place}</strong>,<br>
          ${t('Hit_1_1')}&nbsp;<strong>${hitted[0].name}</strong>&nbsp;${t(
            'Team'
          )}.`;
        } else if (strike === 2) {
          const teamsHitText =
            teamNames.length === 1
              ? `<strong>${teamNames[0]}</strong>&nbsp;${t('Team')}.`
              : `${t('BothThe')}&nbsp;<strong>${teamNames.join(
                `</strong>&nbsp;${t('AND')}&nbsp;<strong>`
              )}</strong>&nbsp;${t('Temas')}.`;

          text.current = `<strong>${player.teaminfo.name}</strong>&nbsp;${t(
            'Hit_2_1'
          )}&nbsp;<strong>${place}</strong>,<br>
            ${t('Hit_2_2')} ${teamsHitText}`;
        } else if (strike >= 3) {
          const strikeText =
            teamNames.length === 1
              ? `<strong>${teamNames[0]}</strong>&nbsp;${t('Team')}.`
              : `${t('FollowingTeams')}:&nbsp;<strong>${teamNames.join(
                '</strong>, <strong>'
              )}</strong>.`;

          text.current = `${t('RemarkableExpertise')}, <strong>${player.teaminfo.name
            }</strong> ${t('LunchRocket')} <strong>${place}</strong>,<br>
            ${t('RocketType')} ${strike} ${t('RocketHit')} ${strikeText}`;
        }

        if (strike >= 1 && miss >= 1) {
          text.current += `<br />&nbsp;${t(
            'MissCount'
          )}&nbsp;<strong>${miss}</strong>&nbsp;${t('MissMessage')}`;
        }
      }, 5000);
      function processEvent(index, totalTimeout) {
        if (!Watching) return;
        if (index >= shuffledEvents.length) {
          for (let x = 0; fall >= x; fall--) {
            if (fall !== 0) {
              setTimeout(() => {
                Play(ship_fall);
              }, fall * 1000);
            }
          }
          return;
        }
        const currentEvent = shuffledEvents[index];
        setTimeout(() => {
          if (currentEvent === 'hit') {
            Play(rocket_hit);
            const hitTimeout = 2000;
            setTimeout(() => {
              processEvent(index + 1, totalTimeout + hitTimeout);
            }, hitTimeout);
          } else if (currentEvent === 'miss') {
            Play(rocket_miss);
            const missTimeout = 4000;
            setTimeout(() => {
              processEvent(index + 1, totalTimeout + missTimeout);
            }, missTimeout);
          }
        }, 0);
      }

      let rocketPlace = [];
      rocketFire.current.forEach((value, index) => {
        rocketPlace.push({ rocket: value, place: place[index] });
      });
      rocketPlace.forEach(p => {
        animateRocketToButton(p.place, p.rocket);
      });
    });
    socket.emit('getTurnRocket', roomID);
    socket.on(
      'turnRocketinfo',
      ({ rocket, Orientation }, currentPlayerName) => {
        let rockets = [];
        switch (rocket) {
          case 'Single':
            rockets.push(5);
            break;
          case 'Triple':
            if (Orientation === 'Horizontal') rockets.push(4, 5, 6);
            else if (Orientation === 'Vertical') rockets.push(2, 5, 8);
            break;
          case 'X':
            rockets.push(1, 3, 5, 7, 9);
            break;
          case 'BigBang':
            rockets.push(1, 2, 3, 4, 5, 6, 7, 8, 9);
            break;
          default:
            break;
        }
        for (let i = 0; i < 9; i++) {
          let rocket = document.getElementById(`rocket${i + 1}`);
          rocket.style.opacity = 0;
        }
        rockets.forEach(n => {
          let rocket = document.getElementById(`rocket${n}`);
          rocket.style.opacity = 1;
        });
        rocketFire.current = rockets;
        setTurn(currentPlayerName);
      }
    );
    socket.emit('getFiredRocket', roomID);
    // socket.on('rocketSent', rocketSent => {
    //   rocketSent.forEach(p => {
    //     let fire = document.getElementById(`fire-${p}`);
    //     fire.style.display = 'block';
    //   });
    // });
    socket.on('TeamWon', teams => {
      if (teams.length === 1) {
        WinTeam.current = `${t('Winner')} ${teams[0].teaminfo.name}.`;
      } else if (teams.length === 2) {
        WinTeam.current = `${t('Tie')} ${teams[0].teaminfo.name} ${t('AND')} ${teams[1].teaminfo.name
          }.`;
      } else {
        WinTeam.current = `${t('Tie')} ${teams
          .map(x => x.teaminfo.name)
          .join(', ')}.`;
      }
      Win.onOpen();
    });
    onOpen();
  }, [onOpen, roomID, animateRocketToButton, buttonsRef]);

  useEffect(() => {
    [...Array(9)].forEach((_, index) => {
      const valueForId = rocketPosition.reduce(
        (maxIndexPosition, currentPosition) => {
          if (
            currentPosition.id === index + 1 &&
            currentPosition.index > maxIndexPosition.index
          ) {
            return currentPosition;
          } else {
            return maxIndexPosition;
          }
        },
        { index: -1 }
      );
      if (!valueForId || valueForId.index === -1) return;
      const leftValueForId = valueForId.left;
      const topValueForId = valueForId.top;
      const css = `
    @keyframes rocket-launch${index + 1} {
      0% {
        top: ${[4, 5, 6].includes(index + 1)
          ? '39.25rem'
          : [1, 2, 3].includes(index + 1)
            ? '34rem'
            : '46.5rem'
        };
        left: ${[1, 4, 7].includes(index + 1)
          ? '112.3rem'
          : [2, 5, 8].includes(index + 1)
            ? '118.4rem'
            : '124.5rem'
        };
        transform: rotate(0deg);
        clip-path: inset(0px 0px 38.3px);
      }
      5% {
        clip-path: inset(0px 0px 0px);
      }
      25% {
        top: ${[4, 5, 6].includes(index + 1)
          ? '15rem'
          : [1, 2, 3].includes(index + 1)
            ? '18rem'
            : '12rem'
        };
        left: ${[1, 4, 7].includes(index + 1)
          ? '106.3rem'
          : [2, 5, 8].includes(index + 1)
            ? '110.4rem'
            : '116.5rem'
        };
        transform: rotate(-43.3deg);
      } 
      50% {
        top: ${[4, 5, 6].includes(index + 1)
          ? '5rem'
          : [1, 2, 3].includes(index + 1)
            ? '8rem'
            : '2rem'
        };
        left: ${[1, 4, 7].includes(index + 1)
          ? '88.9rem'
          : [2, 5, 8].includes(index + 1)
            ? '95rem'
            : '101.1rem'
        };
        transform: rotate(-86.8deg);
      }
      75% {
        top: ${[4, 5, 6].includes(index + 1)
          ? '10rem'
          : [1, 2, 3].includes(index + 1)
            ? '13rem'
            : '7rem'
        };
        left: ${[1, 4, 7].includes(index + 1)
          ? '63.9rem'
          : [2, 5, 8].includes(index + 1)
            ? '70rem'
            : '76.1rem'
        };
      }
      85%, 100% {
        top: ${topValueForId - 10}rem;
        left: ${leftValueForId}rem;
        transform: rotate(-140deg);
        clip-path: inset(0px 0px 0px);
      }
    }
  `;
      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode(css));
      document.head.appendChild(style);
      setTimeout(() => {
        document.head.removeChild(style);
      }, 5000);
    });
  }, [rocketPosition]);
  const handleWatchingModalClick = () => {
    onClose();
    Watching.current = true;
  };
  const handleWinModalClick = () => {
    Win.onClose();
  };
  const { i18n } = useTranslation();
  const changeLanguage = props => {
    const lang = props.target.value;
    i18n.changeLanguage(lang);
  };
  return (
    <Box className="game-root">
      <Flex id="lang-flex">
        <MdLanguage id="lang-icon" />
        <Select
          id="lang-select"
          onChange={changeLanguage}
          value={i18n.language}
        >
          <option value="en">English</option>
          <option value="ar">Arabic</option>
        </Select>
      </Flex>
      <Text className="turn-team-name">
        {turn ? `${t('Its')} ${turn} ${t('Turn')}` : ''}
      </Text>
      <Flex>
        <Box className="battle-grid-watch">
          <BattleGrid {...props} />
        </Box>
        {[...Array(9)].map((_, index) => (
          <Image
            src={rocket_png}
            alt="rocket"
            key={`rocket${index + 1}`}
            style={{
              animation:
                animate && rocketFire.current.includes(index + 1)
                  ? `rocket-launch${index + 1} 5s linear`
                  : 'none',
              position: 'absolute',
              top: [4, 5, 6].includes(index + 1)
                ? '39.25rem'
                : [1, 2, 3].includes(index + 1)
                  ? '34rem'
                  : '46.5rem',
              left: [1, 4, 7].includes(index + 1)
                ? '112.3rem'
                : [2, 5, 8].includes(index + 1)
                  ? '118.4rem'
                  : '124.5rem',
              clipPath: 'inset(0 0 38.6px 0)',
            }}
            className="rocket"
            id={`rocket${index + 1}`}
          />
        ))}
      </Flex>
      <Text
        className="hit-miss-text"
        dangerouslySetInnerHTML={{ __html: text.current }}
      />

      <Modal isOpen={isOpen} onClose={onClose} className="watch-modal">
        <ModalOverlay />
        <ModalContent>
          <Button
            colorScheme="blue"
            onClick={handleWatchingModalClick}
            className="watch-modal-button"
          >
            {t('StartWatching')}
          </Button>
        </ModalContent>
      </Modal>
      <Modal isOpen={Win.isOpen} onClose={Win.onClose}>
        <ModalOverlay />
        <ModalContent className="watch-modal-win" borderRadius="md">
          <Text
            colorScheme="blue"
            onClick={handleWinModalClick}
            className="watch-modal-text-win"
          >
            {WinTeam.current}
          </Text>
        </ModalContent>
      </Modal>
    </Box>
  );
}
