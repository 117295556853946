import { useState, useEffect } from 'react';
import { getUserDetails } from "../utils/functions";

export function useAuth() {
    const [user, setUser] = useState(false);
    useEffect(() => {
        getUserDetails().then(({ data }) => {
            setUser(data);
        }).catch(() => {
            setUser(null);
        });
    }, []);
    return { user }
}

