import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Stack,
  RadioGroup,
  Radio,
  Text,
  Select,
} from '@chakra-ui/react';
import './index.css';

export function GameInfo({
  roomID,
  socket,
  teams,
  turn,
  rocket,
  setRocket,
  Orientation,
  setOrientation,
  t,
}) {
  const [Game, setGame] = useState();
  const [TeamsAlive, setTeamsAlive] = useState(0);
  const [TeamsLost, setTeamsLost] = useState(0);
  const [hits, setHits] = useState(0);
  useEffect(() => {
    let Lost = 0;
    let Alive = 0;
    let Hits = 0;
    socket.on('GameInfo', game => {
      setGame(game);
    });
    Game?.players.forEach(i => {
      if (i.teaminfo.lost) Lost++;
      else Alive++;
      setTeamsLost(Lost);
      setTeamsAlive(Alive);
      Hits += i.teaminfo.hit;
      setHits(Hits);
    });
  }, [Game, socket, roomID]);
  useEffect(() => {
    socket.emit('getGameInfo', roomID);
  }, [roomID, socket]);

  function handleRocketChange(value) {
    setRocket(value);
    socket.emit('RocketUpdate', roomID, value, Orientation);
  }
  function handleOrientationChange(e) {
    setOrientation(e.target.value);
    socket.emit('RocketUpdate', roomID, rocket, e.target.value);
  }
  return (
    <Box className="gameinfo-root">
      <Box className="info-wrap">
        <Box>
          <Box className="info-item">
            <Heading className="info-text">{t('Game_Info')}</Heading>
          </Box>
          <Box className="info-item">
            <Text className="info-text">
              {t('Number_Teams')} {teams.length}
            </Text>
            <Text className="info-text">
              {t('Rocket_Fired')}: {Game?.hits.length}
            </Text>
            <Text className="info-text">
              {t('Hit')}: {hits}
            </Text>
            <Text className="info-text">
              {t('Missed')}: {Game?.hits.length - hits}
            </Text>
            <Text className="info-text">
              {t('Teams_Alive')}: {TeamsAlive}
            </Text>
            <Text className="info-text">
              {t('Teams_Lost')}: {TeamsLost}
            </Text>
            <Text className="info-text">
              {t('Last_Turn')}: {Game?.lastPlayerTurn}
            </Text>
            <Text className="info-text">
              {t('Current_Turn')}: {turn}
            </Text>
            <Text className="info-text">
              {t('Rocket_Type')}: {t(rocket)}
            </Text>
          </Box>
        </Box>
        <Box>
          <Box className="info-item">
            <Heading className="info-text">{t('Abilities')}</Heading>
          </Box>
          <Box className="info-item">
            <Text className="rocket-text">{t('Choose_Rocket')}:</Text>
            <RadioGroup onChange={handleRocketChange} value={rocket}>
              <Stack direction="column" className="rocket-radio">
                <Radio value='Single' sx={{ borderRadius: '0.3125rem' }}>
                  {t('Single_R')}
                </Radio>
                <Radio value='Triple' sx={{ borderRadius: '0.3125rem' }}>
                  {t('Triple_R')}
                </Radio>
                <Radio value='X' sx={{ borderRadius: '0.3125rem' }}>
                  {t('X_R')}
                </Radio>
                <Radio
                  value='BigBang'
                  sx={{ borderRadius: '0.3125rem' }}
                  color="green"
                >
                  {t('B_B_X')}
                </Radio>
              </Stack>
            </RadioGroup>

            {rocket === 'Triple' && (
              <Box>
                <Text className="rocket-Orientation">{t('Orientation')}:</Text>
                <Select
                  value={Orientation}
                  onChange={handleOrientationChange}
                  size="lg"
                  bg="white"
                >
                  <option value="Horizontal" className="Orientation-option">
                    {t('H')}
                  </option>
                  <option value="Vertical" className="Orientation-option">
                    {t('V')}
                  </option>
                </Select>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
