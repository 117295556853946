import React from 'react';
import { Tbody, Tr, Td, Button, Heading, Box } from '@chakra-ui/react';
import './index.css';
import { GetAllAccountsQuery, UpdateAccess } from '../../graphql';
import { useQuery, useMutation } from '@apollo/client';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
let temp = false;
export function AllAcounts({ setMax, page, infod, setinfo }) {
  const { data, error, loading } = useQuery(GetAllAccountsQuery);
  const [updateAccess] = useMutation(UpdateAccess);
  async function accessButton(access, email, firstname, secondname) {
    let new1 = {
      email: email,
      firstname: firstname,
      secondname: secondname,
      access: access ? false : true,
    };
    let info = [...infod];
    let i = infod.findIndex(i => i.email === email);
    info.splice(i, 1, new1);

    setinfo(info);
    return await updateAccess({
      variables: {
        email: email,
        access: access ? false : true,
      },
    });
  }
  if (!loading && !error) {
    let numInPage = 5;
    let noOfPages = infod.length / numInPage;
    let I = page > 0 && page < noOfPages + 1 ? page : 1;
    I = I - 1;
    setMax(Math.trunc(noOfPages + 1));
    let info = data.getAllAccount.info;
    setTimeout(() => {
      if (temp) return;
      setinfo(info);
      temp = true;
    }, 2000);

    return (
      <Tbody className="table-text">
        {infod
          .map(({ email, firstname, secondname, access }) => {
            return (
              <Tr>
                <Td>
                  {firstname} {secondname}
                </Td>
                <Td>
                  {email.length > 21 ? email.slice(0, 19) + '...' : email}
                </Td>
                <Td className="access-button">
                  <Button
                    onClick={() =>
                      accessButton(access, email, firstname, secondname)
                    }
                  >
                    {access ? <UnlockIcon /> : <LockIcon />}
                  </Button>
                </Td>
              </Tr>
            );
          })
          .slice(I * numInPage, I * numInPage + numInPage)}
      </Tbody>
    );
  } else {
    return (
      <Tbody className="table-text">
        <Tr>
          <Td>
            <Heading className="account-table-wait"></Heading>
          </Td>
          <Td>
            <Heading className="account-table-wait">Wait...</Heading>
          </Td>
          <Td>
            <Heading className="account-table-wait"></Heading>
          </Td>
        </Tr>
      </Tbody>
    );
  }
}
