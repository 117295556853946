import { gql } from '@apollo/client';

export const AddAccount = gql`
  mutation AddAccount(
    $email: String!
    $password: String
    $firstname: String
    $secondname: String
  ) {
    AddAccount(
      email: $email
      password: $password
      firstname: $firstname
      secondname: $secondname
    ) {
      info {
        email
        password
        firstname
        secondname
      }
    }
  }
`;
export const UpdateAccess = gql`
  mutation Access($email: String!, $access: Boolean!) {
    Access(email: $email, access: $access) {
      info {
        email
        password
        firstname
        secondname
        access
      }
    }
  }
`;
