import React, { useEffect, useState } from 'react';
import { Grid, useDisclosure, useToast, Text } from '@chakra-ui/react';
import {
  CantH,
  CantV,
  PlaceShips,
  handleMouseEvents,
  getRocketInfoPromise,
  canHit,
  handlePlayerShipPlacment,
} from '../../utils/functions';
import { ShotConfirmation, AdminConfirmation } from '../index';
import img from '../../assets/fire.png';
import './index.css';
import './linear.css';
import './ships.css';
import { GridButton } from '../GridButton';
export function BattleGrid({
  gridSize,
  socket,
  type,
  VH,
  shipSelected,
  setShipSelected,
  shipPlaces,
  setShipPlaced,
  teams,
  setTeams,
  started,
  myTurn,
  roomID,
  value,
  rocket,
  Orientation,
  setRocket,
  setOrientation,
  rocketP,
  setRocketP,
  OrientationP,
  setOrientationP,
  t,
}) {
  const [clicked, setClicked] = useState(0);
  const [Game, setGame] = useState();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const prop = {
    isOpen,
    onClose,
    onOpen,
    clicked,
    socket,
    roomID,
    type,
    value,
    setRocket,
    setOrientation,
    rocketP,
    setRocketP,
    OrientationP,
    setOrientationP,
    t,
  };
  useEffect(() => {
    socket.on('AfterHit', async info => {
      setTimeout(() => {
        const { place, teamName, strike, miss, fall } = info;
        if (type === 'Admin') {
          place.forEach(p => {
            let fire = document.getElementById(`fire-${p}`);
            fire.style.display = 'block';
          });
        } else if (type === 'Player') {
          let team_name =
            document.getElementsByClassName('team-name')[0].outerText;
          if (team_name === teamName) {
            place.forEach(p => {
              let fire = document.getElementById(`fire-${p}`);
              fire.style.display = 'block';
            });
          }
        } else if (type === 'Watch') {
          let time = strike * 2000 + miss * 4000 + fall * 1000;
          place.forEach(p => {
            let fire = document.getElementById(`fire-${p}`);
            fire.style.display = 'block';
            setTimeout(() => (fire.style.display = 'none'), time + 1000);
          });
        }
      }, 5000);
    });
    if (type === 'Player') return;
    socket.on('PlayerLeft', async player => {
      let { teaminfo } = player;
      if (!teams || teams.length === 0) return;
      let teamIndex = teams.findIndex(x => x.name === teaminfo.name);
      if (teamIndex === -1) return;
      let ships = teams[teamIndex].ships;
      await ships.forEach(async ship => {
        await ship.places.forEach(i => {
          let place = document.getElementById(`GridNumber-${i}`);
          place.classList.remove(`team${teamIndex + 1}`);
        });
      });
      await teams.forEach((info, index) => {
        if (index <= teamIndex) return;
        info.ships.forEach(inf => {
          inf.places.forEach(number => {
            let place = document.getElementById(`GridNumber-${number}`);
            place.classList.remove(`team${index + 1}`);
            place.classList.add(`team${teamIndex + 1}`);
          });
        });
        teamIndex++;
      });
    });
  }, [teams, gridSize, socket, type]);
  useEffect(() => {
    socket.connect();
    socket.on('GameInfo', game => {
      setGame(game);
    });
    socket.on('informAdminPlayerDisconnect', player => {
      if (type === 'Player') return;
      toast({
        title: `${player.teaminfo.name} ${t('Player_Left')}`,
        description: `${t('If the team')} ${player.teaminfo.name} ${t(
          'Player_Left_Desc'
        )}`,
        status: 'warning',
        duration: 8000,
        isClosable: true,
      });
    });
    socket.on('reconnectInfoReturn', (q, w, e, name) => {
      toast({
        title: `${name} ${t('Recoonect')}`,
        status: 'success',
        duration: 8000,
        isClosable: true,
      });
    });
  }, []);
  let cantH = CantH(gridSize, shipSelected);
  let cantV = CantV(gridSize, shipSelected);

  function createMouseoverHandler(value) {
    if (!started && type === 'Player') {
      handlePlayerShipPlacment(
        value,
        VH,
        shipSelected,
        true,
        gridSize,
        shipPlaces
      );
    } else {
      handleMouseEvents(
        value,
        true,
        rocket,
        Orientation,
        gridSize,
        socket,
        roomID
      );
    }
  }

  function createMouseoutHandler(value) {
    if (!started && type === 'Player') {
      handlePlayerShipPlacment(
        value,
        VH,
        shipSelected,
        false,
        gridSize,
        shipPlaces
      );
    } else {
      handleMouseEvents(
        value,
        false,
        rocket,
        Orientation,
        gridSize,
        socket,
        roomID
      );
    }
  }

  const handlePlayerClick = button => {
    if (type !== 'Player') return;
    setClicked(button);
    if (!started) {
      PlaceShips(
        shipSelected,
        toast,
        VH,
        button,
        cantH,
        cantV,
        shipPlaces,
        setShipPlaced,
        setShipSelected,
        started
      );
    } else {
      if (!Game) return;
      let Player = Game.players.find(x => x.teaminfo.name === value);
      let Lost = Player.teaminfo.lost;
      if (Lost)
        return toast({
          title: t('Lost'),
          description: t('Ship_Sunk'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      if (!myTurn)
        return toast({
          title: t('Wait_Turn'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      getRocketInfoPromise(rocket, Orientation, roomID, socket).then(
        ({ r, O }) => {
          let checkCanHit = canHit(r, O, button, gridSize);
          if (checkCanHit.canhit) {
            onOpen();
            setClicked(checkCanHit.hit);
          } else {
            toast({
              title: t('NotValid_Target'),
              description: t('Rocket_TooLarge'),
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
          }
        }
      );
    }
  };
  if (type === 'Admin') {
    teams.forEach((info, i) => {
      if (info.PlacedOnAdmin) return;
      info.ships.forEach(inf => {
        inf.places.forEach(number => {
          let place = document.getElementById(`GridNumber-${number}`);
          place.classList.add(`team${i + 1}`);
        });
      });

      teams[i].PlacedOnAdmin = true;
      const updatedInfo = { ...info, PlacedOnAdmin: true };
      const updatedTeams = [...teams];
      updatedTeams[i] = updatedInfo;
      setTeams(updatedTeams);
      socket.emit('updatePlayerInfo', roomID, teams[i]);
    });
  }
  return (
    <div className="grid-root">
      <Grid templateColumns="repeat(10, 1fr)" gap={0.5} className="Grid">
        {[...Array(Number(gridSize))].map((e, i) => (
          <GridButton
            id={i + 1}
            handleOnClick={handlePlayerClick}
            handleMouseOver={createMouseoverHandler}
            handleMouseOut={createMouseoutHandler}
            imgSrc={img}
            key={i + 1}
          />
        ))}
      </Grid>
      <Text className="Text-under-grid" id="shot-text"></Text>
      {type === 'Player' ? (
        <ShotConfirmation {...prop} />
      ) : (
        <AdminConfirmation {...prop} />
      )}
    </div>
  );
}
