import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { LandingPage, DashboardPage, AdminPage, GameAdminPage, GamePlayerPage, GameWatchPage } from './pages';
import { useTranslation } from 'react-i18next';
function App() {
  let history = useNavigate();
  const { t } = useTranslation();

  let porps = { history, t };
  return (
    <Routes>
      <Route path="/" exact={true} element={<LandingPage {...porps} />} />
      <Route path="/main" exact={true} element={<DashboardPage {...porps} />} />
      <Route path="/admin" exact={true} element={<AdminPage {...porps} />} />
      <Route path="/game/:roomID" exact={true} element={<GamePlayerPage {...porps} />} />
      <Route path="/game/:roomID/admin" exact={true} element={<GameAdminPage {...porps} />} />
      <Route path="/game/:roomID/watch" exact={true} element={<GameWatchPage {...porps} />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
}

export default App;
