import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  useToast,
} from '@chakra-ui/react';
import './index.css';
import { copyTextToClipboard } from '../../utils/functions';

export function TeamsTable({ roomID, socket, teams, setTeams, t }) {
  const [start, setStart] = useState(false);
  const toast = useToast();
  useEffect(() => {
    socket.emit('NewGame', roomID);
    socket.on(`TeamConnect`, info => {
      setTeams(old => [...old, info]);
    });
    socket.on('AlreadyStarted', info => {
      toast({
        title: `${info.name} ${t('Join_After_Start')}`,
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
    });
    socket.on('PlayerLeft', info => {
      console.log(info)
      setTeams(old => old.filter(x => x.name !== info.teaminfo.name));
      toast({
        title: `${info.teaminfo.name} ${t('Player_Left_Removal')}`,
        status: 'warning',
        duration: 8000,
        isClosable: true,
      });
    });
  }, [roomID, setTeams, socket, toast]);
  let hendleStartGameClick = () => {
    socket.emit('GameStarted', roomID);
    setStart(true);
  };
  let handleInviteClick = () => {
    copyTextToClipboard(`https://${window.location.host}/#/game/${roomID}`);
    toast({
      title: 'Invite link copied.',
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
  };
  let handleWatchClick = () => {
    window.open(`/#/game/${roomID}/watch`, '_blank');
  };
  const renderTeams = () => {
    let i = 0;
    if (!teams || teams.length === 0)
      return (
        <Tr key={i++}>
          <Td key={i++}>{t('No_Teams')}</Td>
        </Tr>
      );
    else
      return teams.map(({ name, color }, i) => (
        <Tr key={i++}>
          <Td key={i++} color={color} className={`team${i + 1}-text`}>
            {name}
          </Td>
        </Tr>
      ));
  };
  return (
    <Box className="teamlist-root">
      <TableContainer>
        <Table variant="simple" className="tabel-teams">
          <Thead>
            <Tr>
              <Th>{t('Teams_Table')}</Th>
            </Tr>
          </Thead>
          <Tbody>{renderTeams()}</Tbody>
        </Table>
      </TableContainer>
      <Box className="teams-button">
        <Button
          onClick={() => handleInviteClick()}
          className="invite-button Text teams-btn"
          display={!start ? 'block' : 'none'}
        >
          {t('Invite_Players')}
        </Button>
        <Button
          onClick={() => handleWatchClick()}
          className="watch-button Text teams-btn"
        >
          {t('Watch_Game')}
        </Button>
        <Button
          className="Text teams-btn"
          onClick={() => hendleStartGameClick()}
          display={!start ? 'block' : 'none'}
        >
          {t('Start_Game')}
        </Button>
      </Box>
    </Box>
  );
}
