import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import './index.css';
import { onlyEmail, onlyLetters } from '../../utils/functions';
import Axios from 'axios';
export function Signup({ setTabIndex, history }) {
  const [showPassword, setShowPassword] = useState(false);

  const [inputFN, setInputFN] = useState('');
  const handleInputChangeFN = e => setInputFN(e.target.value);

  const [inputSN, setInputSN] = useState('');
  const handleInputChangeSN = e => setInputSN(e.target.value);

  const [inputE, setInputE] = useState('');
  const handleInputChangeE = e => setInputE(e.target.value);

  const [inputP, setInputP] = useState('');
  const handleInputChangeP = e => setInputP(e.target.value);

  const [isErrorAccount, setIsErrorAccount] = useState('none');

  const isError = inp => {
    return inp === '' || !onlyLetters(inp) || inp.length < 3;
  };
  const isErrorE = inp => {
    return inp === '' || !onlyEmail(inp);
  };
  const isErrorP = inp => {
    return inp === '' || inp.length < 8;
  };
  const Addaccount = async (email, password, firstname, secondname) => {
    if (
      !email ||
      !password ||
      !firstname ||
      !secondname ||
      isError(firstname) ||
      isError(secondname) ||
      isErrorE(email) ||
      isErrorP(password)
    )
      return setIsErrorAccount('missing');
    try {
      Axios({
        method: 'POST',
        data: {
          email,
          password,
          firstname,
          secondname,
        },
        withCredentials: true,
        headers: {"Access-Control-Allow-Origin": "*"},
        url: `${process.env.REACT_APP_HTTP_S}://${process.env.REACT_APP_IP_ADDRESS}/register`,
      })
        .then(() => {
          setIsErrorAccount('created');
          history('/main');
        })
        .catch(() => setIsErrorAccount('have'));
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Flex
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8}>
        <Stack align={'center'}>
          <Heading
            fontSize={'3xl'}
            textAlign={'center'}
            className="create-account-text"
          >
            Create Your Account
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4} className="create-account-text">
            {isErrorAccount === 'have' ? (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>
                  An account with this email already exists.
                </AlertTitle>
              </Alert>
            ) : isErrorAccount === 'created' ? (
              <Alert status="success">
                <AlertIcon />
                <AlertTitle>
                  Your account has been successfully created.
                </AlertTitle>
              </Alert>
            ) : isErrorAccount === 'missing' ? (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>
                  Please ensure that all fields are filled out completely.
                </AlertTitle>
              </Alert>
            ) : null}
            <HStack>
              <Box>
                <FormControl
                  id="firstName"
                  isRequired={isError(inputFN)}
                  isInvalid={isError(inputFN)}
                >
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    onChange={handleInputChangeFN}
                    value={inputFN}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl
                  id="lastName"
                  isRequired={isError(inputSN)}
                  isInvalid={isError(inputSN)}
                >
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    onChange={handleInputChangeSN}
                    value={inputSN}
                  />
                </FormControl>
              </Box>
            </HStack>
            <FormControl
              id="email"
              isRequired={isErrorE(inputE)}
              isInvalid={isErrorE(inputE)}
            >
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                onChange={handleInputChangeE}
                value={inputE}
              />
            </FormControl>
            <FormControl
              id="password"
              isRequired={isErrorP(inputP)}
              isInvalid={isErrorP(inputP)}
            >
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleInputChangeP}
                  value={inputP}
                />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword(showPassword => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                loadingText="Submitting"
                size="lg"
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={() => Addaccount(inputE, inputP, inputFN, inputSN)}
              >
                Sign up
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Already a user?{' '}
                <Link
                  color={'blue.400'}
                  onClick={() => {
                    setTabIndex(1);
                  }}
                >
                  Login
                </Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
