import React, { useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  useToast,
} from '@chakra-ui/react';
import './index.css';
export function ShotConfirmation({
  onClose,
  isOpen,
  clicked,
  socket,
  roomID,
  value,
  rocketP,
  setRocketP,
  OrientationP,
  setOrientationP,
  t,
}) {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();

  const handleClick = () => {
    socket.emit(
      'PlayerTakePermission',
      roomID,
      clicked,
      rocketP,
      OrientationP,
      sessionStorage.getItem('sessionId')
    );
    onClose();
    toast({
      title: t("Req_Admin"),
      status: 'info',
      duration: 4000,
      isClosable: true,
    });
  };
  useEffect(() => {
    socket.on('AdminRefusedPlayer', teamName => {
      if (value !== teamName) return;
      toast({
        title: t("Admin_Refuse"),
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
    });
    socket.on('AdminConfirmStrike', teamName => {
      if (value !== teamName) return;
      toast({
        title: t("Admin_Confirm"),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      socket.emit('changeTurn', roomID);
      socket.emit('getGameInfo', roomID);
    });
    socket.on('RocketUpdated', (rocket, Orientation) => {
      setRocketP(rocket);
      setOrientationP(Orientation);
    });
  }, [value, roomID, socket, toast, setRocketP, setOrientationP]);

  let clickedString = String(clicked);
  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="model-text">
          {t("Sure1")} {rocketP} {t("Sure2")} {clickedString}?
        </ModalHeader>
        <ModalFooter className="model-footer">
          <Button
            colorScheme="blue"
            className="model-text model-button"
            onClick={handleClick}
          >
            {t("Confirm")}
          </Button>
          <Button
            colorScheme="red"
            className="model-text model-button"
            onClick={onClose}
          >
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
