import React, { useState } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tr,
  Th,
  TableCaption,
  TableContainer,
  Button,
  useNumberInput,
  Input,
} from '@chakra-ui/react';
import './index.css';
import { AllAcounts } from '../../components';
import {
  ArrowBackIcon,
  ArrowDownIcon,
  ArrowForwardIcon,
  ArrowUpIcon,
} from '@chakra-ui/icons';
import { dynamicSort, getUserDetails } from '../../utils/functions';
import { NaviBar } from '../../NaviBar';

export function AdminPage({ history, t }) {
  getUserDetails()
    .then(({ data }) =>
      process.env.REACT_APP_ADMINS_EMAIL.split(',').includes(data.email)
        ? null
        : history('/')
    )
    .catch(() => history('/'));

  const [infod, setinfo] = useState([]);
  const [page, setPage] = useState(1);
  const [max, setMax] = useState(1);
  const [nameSort, setNameSort] = useState(true);
  const [emailSort, setEmailSort] = useState(true);
  const [accessSort, setAccessSort] = useState(true);
  const handleClick = sort => {
    switch (sort) {
      case 'firstname':
        setinfo(
          infod.slice().sort(dynamicSort(nameSort ? `${sort}` : `-${sort}`))
        );
        setNameSort(nameSort ? false : true);
        break;
      case 'email':
        setinfo(
          infod.slice().sort(dynamicSort(emailSort ? `${sort}` : `-${sort}`))
        );
        setEmailSort(emailSort ? false : true);
        break;
      case 'access':
        setinfo(
          infod.slice().sort(dynamicSort(accessSort ? `${sort}` : `-${sort}`))
        );
        setAccessSort(accessSort ? false : true);
        break;
      default:
        break;
    }
  };
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max,
      onChange: I => setPage(I),
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  const props = {
    setMax,
    page,
    infod,
    setinfo,
    history,
    t,
  };

  return (
    <div className="admin-root">
      <NaviBar {...props} />
      <Box className="admin-box">
        <Heading className="admin-title">{t("Admin_Page")}</Heading>
        <TableContainer className="table-container" overflowX="scroll">
          <Table variant="simple" className="accounts-table">
            <Thead className="table-text">
              <Tr>
                <Th>
                  {t("Name")}
                  <Button
                    className="SortButton"
                    onClick={() => handleClick('firstname')}
                  >
                    {nameSort ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </Button>
                </Th>
                <Th>
                {t("Email")}
                  <Button
                    className="SortButton"
                    onClick={() => handleClick('email')}
                  >
                    {emailSort ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </Button>
                </Th>
                <Th>
                {t("Access")}
                  <Button
                    className="SortButton"
                    onClick={() => handleClick('access')}
                  >
                    {accessSort ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </Button>
                </Th>
              </Tr>
            </Thead>
            <AllAcounts {...props} />
            <TableCaption>
              <Button {...dec}>
                <ArrowBackIcon />
              </Button>
              <Input {...input} className="Pages-input" isReadOnly={true} />
              <Button {...inc}>
                <ArrowForwardIcon />
              </Button>
            </TableCaption>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
