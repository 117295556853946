import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { StartingModal } from '../../components';
import './index.css';
import { useAuth } from '../../hooks/auth';
import { NaviBar } from '../../NaviBar';
import { getUserDetails } from '../../utils/functions';

export function LandingPage({ history, t }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const prop = { isOpen, onClose, onOpen, history, t };
  async function onClick() {
    try {
      await getUserDetails();
      history("/main");
    } catch (error) {
      onOpen();
    }
  }
  return (
    <div className="landing-root">
      {useAuth().user ? <NaviBar {...prop} /> : null}
      <Box className="main-box">
        <Heading className="main-title">{t("Battle_Ship")}</Heading>
        <Button className="main-btn" onClick={onClick}>
          {t("Get_Started")}
        </Button>
      </Box>
      <StartingModal {...prop} />
    </div>
  );
}
