import React, { useEffect, useState } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import './index.css';

export function TeamsInfo({
  socket,
  teams,
  turn,
  setTurn,
  roomID,
  gridSize,
  t,
}) {
  const [isHandlingMouseOver, setisHandlingMouseOver] = useState(false);
  const [removedClasses, setRemovedClasses] = useState([]);
  const handleMouseOver = teamNumber => {
    if (isHandlingMouseOver) return;
    const updatedRemovedClasses = [];
    for (let i = 0; i <= Number(gridSize); i++) {
      const element = document.getElementById(`GridNumber-${i}`);
      if (element) {
        teams.forEach((info, index) => {
          const updatedIndex = index + 1;
          if (teamNumber !== updatedIndex) {
            if (element.classList.contains(`team${updatedIndex}`)) {
              updatedRemovedClasses.push({
                element,
                className: `team${updatedIndex}`,
              });
              element.classList.remove(`team${updatedIndex}`);
            }
          }
        });
      }
    }
    setRemovedClasses([...removedClasses, ...updatedRemovedClasses]);
  };

  const handleMouseOut = () => {
    if (!removedClasses) return;
    setisHandlingMouseOver(true);
    removedClasses.forEach(({ element, className }) => {
      element.classList.add(className);
      setRemovedClasses(prevClasses =>
        prevClasses.filter(
          cls => cls.element !== element && cls.className !== className
        )
      );
    });
    setTimeout(() => {
      setisHandlingMouseOver(false);
    }, 900);
  };

  useEffect(() => {
    socket.on('TurnChanged', name => {
      setTurn(name);
    });

    if (!turn && teams.length !== 0) {
      socket.emit('changeTurn', roomID, teams[0].name);
      socket.emit('getGameInfo', roomID);
      setTurn(teams[0].name);
    }
  }, [socket, teams, turn, setTurn, roomID]);
  return (
    <Box className="teaminfo-root">
      {teams.map((info, index) => (
        <Box
          id={`team-${info.name}`}
          className={`team-item team-${info.name}${
            info.name === turn || !turn ? ' team-turn' : ' not-team-turn'
          }`}
          key={info.name + index}
          // onMouseOver={() => handleMouseOver(index + 1)}
          // onMouseOut={handleMouseOut}
          onClick={() => {
            socket.emit('changeTurn', roomID, info.name);
            socket.emit('getGameInfo', roomID);
            setTurn(info.name);
          }}
        >
          <Box className="team-name-turn" key={info.name}>
            <Heading className={`team-text team${index + 1}-text`}>
              {info.name}
            </Heading>
          </Box>
          {/* <div className="row">
            <div className="column">
              <Text className="team-text ships-info">
                Rockets Hit: {info.hit}
              </Text>
              <Text className="team-text ships-info" h="2.25rem">
                Rockets Miss: {info.rocketSent.length - info.hit}
              </Text>
            </div>
            <div className="column">
              <Text className="team-text ships-info">
                Rockets Miss: {info.rocketSent.length - info.hit}
              </Text>
              <Text className="team-text ships-info">
                Rockets Fierd: {info.rocketSent.length}
              </Text>
            </div> */}
          {info.shipsAlive.map(ship => (
            <Text className="team-text ships-info" key={ship.shipSize}>
              {t('Ships_Of')} {ship.shipSize}:{' '}
              {ship.places.length === 0 ? t('Drowned') : ship.places.length}
            </Text>
          ))}

          {/* </div> */}
        </Box>
      ))}
    </Box>
  );
}
/* <Text className="team-text ships-info" key={5}>
              Ships Of 5:{' '}
              {info.shipsAlive.places.length === 0 ? 'Drowned' : ship.places.length}
            </Text>
            <Text className="team-text ships-info" key={4}>
              Ships Of 4:{' '}
              {ship.places.length === 0 ? 'Drowned' : ship.places.length}
            </Text>
            <Text className="team-text ships-info" key={3}>
              Ships Of 3:{' '}
              {ship.places.length === 0 ? 'Drowned' : ship.places.length}
            </Text>
            <Text className="team-text ships-info" key={2}>
              Ships Of 2:{' '}
              {ship.places.length === 0 ? 'Drowned' : ship.places.length}
            </Text> */
