export const AUTHORIZED_ROUTES = [
    {
        name: 'Main_Page_nav',
        path: "/main",
        authorized: false,
    },
];
export const ADMIN_ROUTS = [
    {
        name: "Admin_Page_nav",
        path: "/admin",
        authorized: true
    },
];