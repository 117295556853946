import axios from 'axios';

export function onlyLetters(str) {
    return /^[A-Za-z]*$/.test(str);
}
export function onlyEmail(str) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi.test(str);
}
export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}
export function getUserDetails() {
    return axios.get(`${process.env.REACT_APP_HTTP_S}://${process.env.REACT_APP_IP_ADDRESS}/user`, {
        withCredentials: true, headers: { "Access-Control-Allow-Origin": "*" },
    });
}
export async function copyTextToClipboard(text) {
    if (navigator.clipboard) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}
export function CantH(gridSize, shipSize) {
    let cantH = [];
    for (let i = Number(gridSize); i !== 0; i = i - 10) {
        cantH.push(i);
        for (let l = shipSize - 2; l >= 0; l--) {
            cantH.push(i - l)
        }
    }
    return cantH;
}
export function CantV(gridSize, shipSize) {
    let cantV = [];
    for (let i = Number(gridSize); i !== Number(gridSize) - 10; i--) {
        cantV.push(i);
        for (let l = shipSize - 2; l >= 0; l--) {
            cantV.push(i - 10 * l)
        }
    }
    return cantV;
}
function getShipPlace(hv, shipSize, button) {
    let info = {
        shipSize,
        hv,
        places: [],
    };
    if (hv === "horizontal") {
        for (let i = shipSize - 1; i >= 0; i--) {
            info.places.push(button + i);
        }
    } else if (hv === "vertical") {
        for (let i = shipSize - 1; i >= 0; i--) {
            info.places.push(button + 10 * i);
        }
    }
    return info;
}
function checkifValid(placedShips, newPlaces) {
    let allShips = [];
    let newShips = newPlaces.places;
    placedShips.forEach((info) => {
        info.places.forEach((sh) => {
            allShips.push(sh);
        });
    });
    if (findCommonElement(allShips, newShips)) return false;
    return true;
}
function findCommonElement(array1, array2) {
    for (let i = 0; i < array1.length; i++) {
        for (let j = 0; j < array2.length; j++) {
            if (array1[i] === array2[j]) {
                return true;
            }
        }
    }
    return false;
}
export function PlaceShips(shipSelected, toast, VH, button, cantH, cantV, shipPlaces, setShipPlaced, setShipSelected, started, Random, ShipPlaced) {
    if (!started && shipSelected === 0 && shipPlaces.length === 4) return toast({
        title: 'Please wait for the admin to start the game.',
        status: 'error',
        duration: 4000,
        isClosable: true,
    });
    if (shipSelected === 0 && !Random)
        return toast({
            title: 'You have to select a ship',
            status: 'error',
            duration: 4000,
            isClosable: true,
        });
    let shipInfo = getShipPlace(VH, shipSelected, button);
    shipInfo.places.sort((a, b) => a - b);
    if (
        (cantH.includes(button) && VH === 'horizontal') ||
        (cantV.includes(button) && VH === 'vertical') ||
        !checkifValid(Random ? ShipPlaced : shipPlaces, shipInfo)
    ) {
        if (Random) {
            return { done: false, ship: shipSelected };
        } else {
            return toast({
                title: "You can't place the ship here",
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    }
    ShipPlaced?.push(shipInfo);
    setShipPlaced([...shipPlaces, shipInfo]);
    addShips(shipInfo.places, VH, shipInfo.shipSize);
    shipInfo.places.forEach(number => {
        removePlacementClasses(number);
    });
    setShipSelected(0);
    return { done: true, ship: shipSelected, shipInfo }
}
function removePlacementClasses(number) {
    let place = document.getElementById(`GridNumber-${number}`);
    place.classList.forEach(cls => {
        if (cls.startsWith("placement")) {
            place.classList.remove(cls);
        }
    });
}
function updateBackgroundColor(elements, color) {
    elements.forEach(element => {
        if (element) {
            if (color) {
                element.style.setProperty('background', color, 'important');
            } else {
                element.style.removeProperty('background');
            }
        }
    });
}
function addShips(ship, VH, size) {
    for (let i = 0; i < size; i++) {
        let place = document.getElementById(`GridNumber-${ship[i]}`);
        place.classList.add("ships-all", `ship${size}-${i + 1}`);
        if (VH === "vertical") {
            place.classList.add("ship-v");
        }
    }
}
function CantHVMouse(gridSize) {
    let H = [[], []];
    let V = [[], []];
    gridSize = Number(gridSize)
    for (let i = gridSize; i > 0; i = i - 10) {
        H[0].push(i);
        H[1].push(i - 9);
    }
    for (let i = gridSize; i !== gridSize - 10; i--) {
        V[0].push(i);
        V[1].push(i - (gridSize - 10));
    }
    return { H, V };
}
function removeElementsFromArray(originalArray, elementsToRemove) {
    const newArray = originalArray.filter(item => !elementsToRemove.includes(item));
    return newArray;
}
export const getRocketInfoPromise = (rocket, Orientation, roomID, socket) => new Promise((resolve) => {
    if (!rocket || !Orientation) {
        socket.emit("getRocketInfo", roomID);
        socket.once("rocketInfo", (r, O) => {
            resolve({ r, O });
        });
    } else {
        resolve({ rocket, Orientation });
    }
});
export function handleMouseEvents(value, mouseover, rocket, Orientation, gridSize, socket, roomID) {
    if (!rocket || !Orientation) {
        return getRocketInfoPromise(rocket, Orientation, roomID, socket).then(({ r, O }) => {
            rocket = r;
            Orientation = O;
            Rocket(value, gridSize, mouseover, rocket, Orientation);
        });
    }
    Rocket(value, gridSize, mouseover, rocket, Orientation);
}
function Rocket(value, gridSize, mouseover, rocket, Orientation) {
    const doc = document.getElementById(`GridNumber-${value}`);
    if (!doc || !doc.style) return;
    const overColor = 'white';
    const overcantColor = 'red';
    const outColor = null;
    let cant = CantHVMouse(gridSize);
    switch (rocket) {
        case 'Single':
            updateBackgroundColor([doc], mouseover ? overColor : outColor);
            break;
        case 'Triple':
            updateTriple(doc, value, Orientation, cant, overColor, overcantColor, outColor, mouseover);
            break;
        case 'X':
            updateX(doc, value, Orientation, cant, overColor, overcantColor, outColor, mouseover);
            break;
        case 'BigBang':
            updateBigBang(doc, value, Orientation, cant, overColor, overcantColor, outColor, mouseover);
            break;
        default:
            break;
    }
}
function updateTriple(doc, value, Orientation, cant, overColor, overcantColor, outColor, mouseover) {
    const id1 = Orientation === 'Horizontal' ? value + 1 : value + 10;
    const id2 = Orientation === 'Horizontal' ? value - 1 : value - 10;
    const doc1 = document.getElementById(`GridNumber-${id1}`);
    const doc2 = document.getElementById(`GridNumber-${id2}`);
    let docs = [doc];
    if ((cant.H[0].includes(value) || cant.H[1].includes(value)) && Orientation === 'Horizontal') {
        docs.push(cant.H[0].includes(value) ? doc2 : doc1);
    } else if ((cant.V[0].includes(value) || cant.V[1].includes(value)) && Orientation === 'Vertical') {
        docs.push(cant.V[0].includes(value) ? doc2 : doc1);
    } else {
        docs.push(doc1, doc2);
    }
    const backgroundColorTriple = mouseover ? (docs.length === 3 ? overColor : overcantColor) : outColor;
    updateBackgroundColor(docs, backgroundColorTriple);
}
function updateX(doc, value, Orientation, cant, overColor, overcantColor, outColor, mouseover) {
    const positionsX = [-11, -9, 11, 9];
    let positionsX1 = [];
    if (cant.H[0].includes(value)) {
        positionsX1 = removeElementsFromArray(positionsX, [11, -9]);
    } else if (cant.H[1].includes(value)) {
        positionsX1 = removeElementsFromArray(positionsX, [-11, 9]);
    } else if (cant.V[0].includes(value)) {
        positionsX1 = positionsX.slice(0, 2);
        positionsX1 = removeElementsFromArray(positionsX, [11, 9]);
    } else if (cant.V[1].includes(value)) {
        positionsX1 = removeElementsFromArray(positionsX, [-11, -9]);
    } else {
        positionsX1 = [...positionsX];
    }
    const elementsX = positionsX1.map(position => document.getElementById(`GridNumber-${value + position}`));
    const backgroundColorX = mouseover ? (elementsX.length + 1 === 5 ? overColor : overcantColor) : outColor;
    updateBackgroundColor([doc, ...elementsX], backgroundColorX);
}
function updateBigBang(doc, value, Orientation, cant, overColor, overcantColor, outColor, mouseover) {
    const positionsBigBang = [-11, -10, -9, -1, 1, 11, 10, 9];
    let positionsBigBang1 = [];
    if (cant.H[0].includes(value)) {
        positionsBigBang1 = removeElementsFromArray(positionsBigBang, [-9, 1, 11]);
    } else if (cant.H[1].includes(value)) {
        positionsBigBang1 = removeElementsFromArray(positionsBigBang, [9, -1, -11]);
    } else if (cant.V[0].includes(value)) {
        positionsBigBang1 = removeElementsFromArray(positionsBigBang, [9, 10, 11]);
    } else if (cant.V[1].includes(value)) {
        positionsBigBang1 = removeElementsFromArray(positionsBigBang, [-9, -10, -11]);
    } else {
        positionsBigBang1 = [...positionsBigBang];
    }
    const elementsBigBang = positionsBigBang1.map(position => document.getElementById(`GridNumber-${value + position}`));
    const backgroundColorBigBang = mouseover ? (elementsBigBang.length + 1 === 9 ? overColor : overcantColor) : outColor;
    updateBackgroundColor([doc, ...elementsBigBang], backgroundColorBigBang);
}
export function canHit(rocket, Orientation, index, gridSize) {
    const cant = CantHVMouse(gridSize);
    let canhit;
    let hit = [];
    switch (rocket) {
        case 'Single':
            canhit = true;
            break;
        case 'Triple':
            const id1 = Orientation === 'Horizontal' ? 1 : 10;
            const id2 = Orientation === 'Horizontal' ? -1 : -10;
            let docs = [];
            if ((cant.H[0].includes(index) || cant.H[1].includes(index)) && Orientation === 'Horizontal') {
                docs.push(cant.H[0].includes(index) ? id2 : id1);
            } else if ((cant.V[0].includes(index) || cant.V[1].includes(index)) && Orientation === 'Vertical') {
                docs.push(cant.V[0].includes(index) ? id2 : id1);
            } else docs.push(id1, id2)

            canhit = docs.length === 2 ? (hit.push(...docs), true) : false;
            break;
        case 'X':
            const positionsX = [-11, -9, 11, 9];
            let positionsX1 = [];
            if (cant.H[0].includes(index)) {
                positionsX1 = removeElementsFromArray(positionsX, [11, -9]);
            } else if (cant.H[1].includes(index)) {
                positionsX1 = removeElementsFromArray(positionsX, [-11, 9]);
            } else if (cant.V[0].includes(index)) {
                positionsX1 = positionsX.slice(0, 2);
                positionsX1 = removeElementsFromArray(positionsX, [11, 9]);
            } else if (cant.V[1].includes(index)) {
                positionsX1 = removeElementsFromArray(positionsX, [-11, -9]);
            } else positionsX1 = [...positionsX];

            canhit = positionsX1.length === 4 ? (hit.push(...positionsX1), true) : false;
            break;
        case 'BigBang':
            const positionsBigBang = [-11, -10, -9, -1, 1, 11, 10, 9];
            let positionsBigBang1 = [index];
            if (cant.H[0].includes(index)) {
                positionsBigBang1 = removeElementsFromArray(positionsBigBang, [-9, 1, 11]);
            } else if (cant.H[1].includes(index)) {
                positionsBigBang1 = removeElementsFromArray(positionsBigBang, [9, -1, -11]);
            } else if (cant.V[0].includes(index)) {
                positionsBigBang1 = removeElementsFromArray(positionsBigBang, [9, 10, 11]);
            } else if (cant.V[1].includes(index)) {
                positionsBigBang1 = removeElementsFromArray(positionsBigBang, [-9, -10, -11]);
            }
            else positionsBigBang1 = [...positionsBigBang];

            canhit = positionsBigBang1.length === 8 ? (hit.push(...positionsBigBang1), true) : false;
            break;
        default:
            break;
    }
    hit = hit.map(element => element + index);
    hit.push(index);
    hit.sort((a, b) => a - b);
    return { canhit, hit };
}
export function hittedNames(hitted) {
    const nameCounts = {};

    hitted.forEach(({ name }) => {
        nameCounts[name] = (nameCounts[name] || 0) + 1;
    });
    const teamNames = Object.entries(nameCounts).map(([name, count]) => {
        return count === 1 ? name : `${name} (${count})`;
    });

    return teamNames;
}
export function getButtonPositions(buttonsRef) {
    const baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

    const positionsInRem = buttonsRef.current.map(button => {
        const buttonRect = button.getBoundingClientRect();
        const id = button.id.slice(11);
        return {
            id: id,
            top: (buttonRect.top + 40) / baseFontSize,
            left: (buttonRect.left + 40) / baseFontSize,
        };
    });

    return positionsInRem;
};
export function Play(src) {
    let sound = new Audio(src);
    sound.play();
}
export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
export function handlePlayerShipPlacment(value, VH, shipSelected, mouseover, gridSize, shipPlaces) {
    const id1 = value;
    let ids = [id1];
    const idIncrement = VH === "horizontal" ? 1 : 10;
    if (shipSelected === 0) return;
    for (let i = 1; i < shipSelected; i++) {
        ids.push(id1 + i * idIncrement);
    }
    let validDoc = checkValidity(ids, shipSelected, VH, gridSize, id1, value);
    let shipsOverlap = checkifValidPlacment(shipPlaces, validDoc.ids);
    const shipDocuments = validDoc.ids.map(id => document.getElementById(`GridNumber-${id}`));

    updatePlacementImage(VH, shipDocuments, shipSelected, (validDoc.isValid && shipsOverlap) ? "valid" : "not-valid", mouseover);

}
function CantHVShip(gridSize) {
    let ships = [2, 3, 4, 5];
    let H = [[], [], [], []];
    let V = [[], [], [], []];
    gridSize = Number(gridSize);
    ships.forEach((ship) => {
        for (let x = 0; x < ship - 1; x++) {
            for (let i = gridSize; i > 0; i = i - 10) {
                H[ship - 2].push(i);
                H[ship - 2].push(i - x);
                H[ship - 2] = Array.from(new Set(H[ship - 2])).sort((a, b) => a - b);
            }

            for (let d = gridSize; d !== gridSize - 10; d--) {
                V[ship - 2].push(d);
                V[ship - 2].push(d - (x * 10));
                V[ship - 2] = Array.from(new Set(V[ship - 2])).sort((a, b) => a - b);
            }
        }
    });

    return { H, V };
}
function updatePlacementImage(VH, docs, shipSize, valid, mouseover) {
    docs.forEach((element, i) => {
        if (!element) return;
        if (mouseover) {
            element.classList.toggle(`placement-${valid}-ship${shipSize}-${i + 1}`, true);
            element.classList.toggle("placement", true);
            if (VH === "vertical") element.classList.toggle("placement-ship-v", true);
            if (element.classList.contains("ship-v")) {
                element.classList.toggle("ship-v", false);
                element.classList.toggle("placement-ship-h", true);
            }
        } else if (!mouseover) {
            element.classList.toggle(`placement-${valid}-ship${shipSize}-${i + 1}`, false);
            element.classList.toggle("placement", false);
            if (VH === "vertical") element.classList.toggle("placement-ship-v", false);
            if (element.classList.contains("placement-ship-h")) {
                element.classList.toggle("ship-v", true);
                element.classList.toggle("placement-ship-h", false);
            }
        }
    });
}
function checkValidity(ids, shipSelected, VH, gridSize, id1, value) {
    const cant = CantHVShip(gridSize);
    const isValid = ids.every(() => {
        const index = shipSelected - 2;
        const cantArray = VH === "horizontal" ? cant.H : cant.V;
        return !cantArray[index].includes(id1);
    });

    if (!isValid) {
        ids.shift();
        ids = ids.filter(id => {
            let index = VH === "horizontal" ? id - value : (id - value) / 10;
            if (index !== 0) index--;
            return VH === "horizontal" ? !cant.H[index].includes(id1) : !cant.V[index].includes(id1);
        });
        ids.push(id1);
        ids.sort((a, b) => a - b);
    }
    return { ids, isValid };
}
function checkifValidPlacment(placedShips, newPlaces) {
    let allShips = [];
    let newShips = newPlaces;
    placedShips.forEach((info) => {
        info.places.forEach((sh) => {
            allShips.push(sh);
        });
    });
    if (findCommonElement(allShips, newShips)) return false;
    return true;
}
