import { gql } from '@apollo/client';

export const GetAllAccountsQuery = gql`
    query getAllAccounteQuery {
        getAllAccount {
           info {
            email
            firstname
            secondname
            access
           }
        }
    }
`;
export const GetAcountQuery = gql`
    query getAccountQuery($email: String!) {
        getAccount(email: $email) {
            info {
                email
                password
                firstname
                secondname
                access
            }
        }
    }
`;