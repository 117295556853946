import React from 'react';
import { Button } from '@chakra-ui/react';

export function ShipSlice({ slice, handleShip }) {
  const slices = [];
  for (let i = 0; i < slice; i++) {
    slices.push(
      <div key={`slice-${i}`} className={`ship-slice ship${slice}-slice`} />
    );
  }

  return (
    <Button
      className="ships-button"
      id={`ship${slice}`}
      onClick={() => handleShip(slice)}
    >
      {slices}
    </Button>
  );
}
