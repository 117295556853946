import React from 'react';
import {
  Box,
  Flex,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  CloseButton,
} from '@chakra-ui/react';
import './index.css';
import { getUserDetails } from '../../utils/functions';
import { NaviBar } from '../../NaviBar';
import { useAuth } from '../../hooks/auth';
export function DashboardPage({ history, t }) {
  getUserDetails().catch(() => history('/'));
  let user = useAuth().user;
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });
  const hendleClick = size => {
    if (!user.access) {
      onOpen();
      setTimeout(() => onClose(), 5000);
    } else {
      history(
        `/game/${user.id}${size}${Math.floor(
          100000 + Math.random() * 100000
        )}/admin`
      );
    }
  };
  return (
    <div className="dashboard-root">
      <NaviBar history={history} t={t} />
      <Flex className="dashboard-card-container">
        <Flex className="card-item" onClick={() => hendleClick('060')}>
          <Box>
            <Text className="card-main-text">10×6 {t('Grid')}</Text>
          </Box>
        </Flex>
      </Flex>
      <Flex className="dashboard-card-container">
        <Flex className="card-item" onClick={() => hendleClick('080')}>
          <Box>
            <Text className="card-main-text">10×8 {t('Grid')}</Text>
          </Box>
        </Flex>
      </Flex>
      <Flex className="dashboard-card-container">
        <Flex className="card-item" onClick={() => hendleClick('100')}>
          <Box>
            <Text className="card-main-text">10×10 {t('Grid')}</Text>
          </Box>
        </Flex>
      </Flex>
      <Flex className="dashboard-card-container">
        <Flex className="card-item">
          <Box>
            <Text className="card-main-text">{t('Comming_Soon')}</Text>
          </Box>
        </Flex>
      </Flex>
      {isVisible ? (
        <Alert status="error" className="no-access-alert">
          <AlertIcon />
          <Box>
            <AlertTitle>{t('Access_Denied_Title')}</AlertTitle>
            <AlertDescription>
              {t('Access_Denied_Desc')
                .split('\\n')
                .map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
            </AlertDescription>
          </Box>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={onClose}
          />
        </Alert>
      ) : null}
    </div>
  );
}
