import React, { useEffect, useRef, useState } from 'react';
import { socket } from '../../socket';
import { useParams } from 'react-router-dom';
import { BattleGrid, Ships } from '../../components';
import {
  Box,
  Button,
  Heading,
  Input,
  useToast,
  Select,
  Flex,
} from '@chakra-ui/react';
import './index.css';
import { useDebounce } from './Debounce';
import { CantV, PlaceShips, CantH } from '../../utils/functions';
import { useTranslation } from 'react-i18next';
import { MdLanguage } from 'react-icons/md';

export function GamePlayerPage({ history, t }) {
  const [value, setValue] = useState('');
  const [joined, setJoined] = useState(false);
  const [started, setStarted] = useState(false);
  const [AlreadyStarted, setAlreadyStarted] = useState(false);
  const [shipSelected, setShipSelected] = useState(0);
  const [VH, setVH] = useState('horizontal');
  const [shipPlaces, setShipPlaced] = useState([]);
  const [sameName, setsameName] = useState(null);
  const [myTurn, setMyTurn] = useState(false);
  const sessionValidate = useRef(false);
  const [rocketP, setRocketP] = useState('Single');
  const [OrientationP, setOrientationP] = useState('Vertical');
  const toast = useToast();
  const { roomID } = useParams();
  const userIdURL = roomID.slice(0, 4);
  const gridSize = roomID.slice(4, 7);
  const props = {
    history,
    socket,
    roomID,
    userIdURL,
    gridSize,
    shipSelected,
    setShipSelected,
    shipPlaces,
    setShipPlaced,
    VH,
    setVH,
    type: 'Player',
    started,
    myTurn,
    value,
    rocketP,
    setRocketP,
    OrientationP,
    setOrientationP,
    t,
  };
  const handleReconnectClick = () => {
    let sessionId = sessionStorage.getItem('sessionId');
    socket.emit('checkPlayerSessionID', roomID, sessionId);
    setTimeout(() => {
      if (!sessionValidate.current)
        return toast({
          title: t('Sorry_Couldnt_Find'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      socket.emit('JoinTeamReconnect', roomID, sessionId);
      socket.emit('reconnectInfo', roomID, sessionId);
    }, 100);
  };
  const handleJoinClick = async () => {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!value)
      return toast({
        title: t('Enter_Team_Name'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    if (shipPlaces.length !== 4)
      return toast({
        title: t('Enter_Ships'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    if (AlreadyStarted)
      return toast({
        title: t('Game_Started'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    if (sameName)
      return toast({
        title: t('Change_Name'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });

    let info = {
      name: value,
      ships: shipPlaces,
      rocketSent: [],
      shipsAlive: shipPlaces,
      shipsLost: [
        { shipSize: 5, places: [] },
        { shipSize: 3, places: [] },
        { shipSize: 4, places: [] },
        { shipSize: 2, places: [] },
      ],
      hit: 0,
      lost: false,
      PlacedOnAdmin: false,
    };
    setJoined(true);
    let grid = document.getElementsByClassName('grid-root');
    grid[0].style.paddingTop = '3.125rem';
    socket.emit('JoinTeam', roomID, info, sessionId);
    socket.emit('getGameInfo', roomID);
  };
  useEffect(() => {
    socket.on('sameName', () => {
      setsameName(true);
    });
    socket.on('NoName', () => {
      setsameName(false);
    });
    socket.on('AlreadyStarted', () => {
      setAlreadyStarted(true);
      toast({
        title: t('Game_Started'),
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    });
    socket.on('gameStart', () => {
      setStarted(true);
      setAlreadyStarted(true);
      toast({
        title: t('Game_Start'),
        status: 'success',
        duration: 7000,
        isClosable: true,
      });
    });
    socket.on('sessionIdSet', (sessionID, id) => {
      if (socket.id !== id) return;
      sessionStorage.setItem('sessionId', sessionID);
    });
    socket.on('playerSessionId', valid => {
      sessionValidate.current = valid;
    });
    socket.on('redrowGrid', (info, sessionId) => {
      if (sessionId !== sessionStorage.getItem('sessionId')) return;
      let shipsinfo = info.ships;
      shipsinfo.forEach(i => {
        let ship = i.shipSize;
        let cantH = CantH(gridSize, ship);
        let cantV = CantV(gridSize, ship);
        PlaceShips(
          ship,
          toast,
          i.hv,
          Math.min(...i.places),
          cantH,
          cantV,
          shipPlaces,
          setShipPlaced,
          setShipSelected,
          started
        );
      });
      setJoined(true);
      setValue(info.name);
    });
    socket.on('reconnectInfoReturn', (turn, isStarted, sessionId) => {
      if (sessionId !== sessionStorage.getItem('sessionId')) return;
      setStarted(isStarted);
      setMyTurn(turn);
    });
  }, [toast]);
  useEffect(() => {
    socket.on('TurnChanged', name => setMyTurn(name !== value ? false : true));
  }, [value]);

  useDebounce(value, 500, socket, roomID);

  let ships = [5, 4, 3, 2];
  let ShipPlaced = [];
  const handleRandomClick = async () => {
    if (ships.length === 4) {
      shipPlaces.forEach(sh => {
        let shipClasses = [];
        for (let i = 1; i <= sh.shipSize; i++) {
          shipClasses.push(`ship${sh.shipSize}-${i}`);
        }
        sh.places.forEach(i => {
          let place = document.getElementById(`GridNumber-${i}`);
          place.classList.remove('ships-all', 'ship-v', ...shipClasses);
        });
      });
    }
    if (ships.length === 0) {
      toast({
        title: t('Ship_Placed'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      return setShipPlaced(ShipPlaced);
    }
    const ship = ships[0];
    setShipSelected(ship);
    const number = Math.floor(Math.random() * (Number(gridSize) - 1 + 1) + 1);
    let cantH = CantH(gridSize, ship);
    let cantV = CantV(gridSize, ship);
    let VH = ['vertical', 'horizontal'][Math.floor(Math.random() * 2)];

    let tt = await PlaceShips(
      ship,
      toast,
      VH,
      number,
      cantH,
      cantV,
      shipPlaces,
      setShipPlaced,
      setShipSelected,
      started,
      true,
      ShipPlaced
    );
    if (tt.done) ships.splice(0, 1);
    await handleRandomClick();
  };
  const { i18n } = useTranslation();
  const changeLanguage = props => {
    const lang = props.target.value;
    i18n.changeLanguage(lang);
  };
  return (
    <div className="game-player-root">
      {' '}
      <Flex id="lang-flex">
        <MdLanguage id="lang-icon" />
        <Select
          id="lang-select"
          onChange={changeLanguage}
          value={i18n.language}
        >
          <option value="en">English</option>
          <option value="ar">Arabic</option>
        </Select>
      </Flex>
      {joined ? <Heading className="team-name">{value}</Heading> : null}
      <BattleGrid {...props} />
      {!joined ? (
        <Box className="new-team">
          <Input
            variant="outline"
            placeholder={t('Team_Name')}
            value={value}
            onChange={x => setValue(x.target.value)}
          />
          <Ships {...props} />
          <Button onClick={() => handleJoinClick()}>{t('Join')}</Button>
          <Button onClick={() => handleRandomClick()} className="Random-btn">
            {t('Random')}
          </Button>
          <Button onClick={() => handleReconnectClick()}>
            {t('Reconnect_BTN')}
          </Button>
        </Box>
      ) : null}
      {joined && !started && !AlreadyStarted ? (
        <Heading className="team-name">{t('Wait_Start')}</Heading>
      ) : null}
      {joined && started && myTurn ? (
        <Heading className="team-name">{t('Turn_To_Strike')}</Heading>
      ) : null}
      {!joined && AlreadyStarted ? (
        <Heading>{t('Game_Started')}</Heading>
      ) : null}
    </div>
  );
}
