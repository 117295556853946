import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Stack,
  Center,
  HStack,
  Select,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { AUTHORIZED_ROUTES, ADMIN_ROUTS } from '../config/routes';
import { useAuth } from '../hooks/auth';
import './index.css';
import Axios from 'axios';
import { MdLanguage } from 'react-icons/md';
import img from '../assets/1.png';
import { useTranslation } from 'react-i18next';
export function NaviBar({ history, t }) {
  let user = useAuth().user;
  const handleClick = async () => {
    Axios({
      method: 'POST',
      data: {
        email: user.email,
      },
      withCredentials: true,
      headers: { 'Access-Control-Allow-Origin': '*' },
      url: `${process.env.REACT_APP_HTTP_S}://${process.env.REACT_APP_IP_ADDRESS}/logout`,
    })
      .then(() => history('/'))
      .catch(() => history('/'));
  };
  const { i18n } = useTranslation();
  const changeLanguage = props => {
    const lang = props.target.value;
    i18n.changeLanguage(lang);
  };
  return (
    <Box px={4} className="drawer-header">
      <Flex
        h={16}
        alignItems={'center'}
        justifyContent={'space-between'}
        className="drawer-header"
      >
        <Avatar size="lg" background={'gray'} src={img} />
        <HStack
          as={'nav'}
          spacing={4}
          display={{ base: 'none', md: 'flex' }}
          className="text-box"
        >
          {process.env.REACT_APP_ADMINS_EMAIL.split(',').includes(user.email)
            ? ADMIN_ROUTS.map(link => (
                <NavLink key={link.name} to={link.path} className="text">
                  {t(link.name)}
                </NavLink>
              ))
            : null}
          {useAuth().user
            ? AUTHORIZED_ROUTES.map(link => (
                <NavLink key={link.name} to={link.path} className="text">
                  {t(link.name)}
                </NavLink>
              ))
            : null}
        </HStack>
        <Flex alignItems={'center'}>
          <MdLanguage id="lang-icon" />
          <Select
            id="lang-select"
            onChange={changeLanguage}
            value={i18n.language}
          >
            <option value="en">English</option>
            <option value="ar">Arabic</option>
          </Select>
          <Stack direction={'row'} spacing={7} className="stack-spacing">
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
              >
                <Avatar
                  size={'md'}
                  background={'gray'}
                  // src={'https://avatars.dicebear.com/api/male/username.svg'}
                />
              </MenuButton>
              <MenuList alignItems={'center'} className="text">
                <br />
                <Center>
                  <Avatar
                    size={'2xl'}
                    background={'gray'}
                    // src={'https://avatars.dicebear.com/api/male/username.svg'}
                  />
                </Center>
                <br />
                <Center>
                  <p>{user.firstname + ' ' + user.secondname}</p>
                </Center>
                <br />

                <MenuDivider />
                <MenuItem
                  isDisabled
                  opacity="100%"
                  color={user.access ? '#00FF00' : '#FF0000'}
                >
                  {user.access ? t('You_Can_Play') : t('For_Access')}
                </MenuItem>
                <MenuItem onClick={() => handleClick()}>{t('Logout')}</MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
}
