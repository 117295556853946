import React, { useEffect, useState } from 'react';
import { NaviBar } from '../../NaviBar';
import { useAuth } from '../../hooks/auth';
import { socket } from '../../socket';
import { useParams } from 'react-router-dom';
import { BattleGrid, GameInfo, TeamsInfo, TeamsTable } from '../../components';
import { Box } from '@chakra-ui/react';
import './index.css';
export function GameAdminPage({ history, t }) {
  const [teams, setTeams] = useState([]);
  const [turn, setTurn] = useState('');
  const [rocket, setRocket] = useState('Single');
  const [Orientation, setOrientation] = useState('Vertical');
  const user = useAuth().user;
  const { roomID } = useParams();
  const userIdURL = roomID.slice(0, 4);
  const gridSize = roomID.slice(4, 7);
  const props = {
    history,
    socket,
    roomID,
    userIdURL,
    gridSize,
    type: 'Admin',
    teams,
    setTeams,
    turn,
    setTurn,
    rocket,
    setRocket,
    Orientation,
    setOrientation,
    t
  };
  useEffect(() => {
    socket.on('AfterHit', async info => {
      let t = [];
      const { game } = info;
      await game.players.map(i => t.push(i.teaminfo));
      setTeams(t);
    });
  });
  const renderContent = () => {
    if (!user || !user.access) return history(`/game/${roomID}`);
    if (userIdURL !== user.id) return history('/main');
    return (
      <div>
        <NaviBar {...props} />
        <Box className="game-admin">
          <TeamsTable {...props} />
          <BattleGrid {...props} />
          <GameInfo {...props} />
        </Box>
        <TeamsInfo {...props} />
      </div>
    );
  };
  if (user) return <Box className="game-root">{renderContent()}</Box>;
}
