import { Button } from '@chakra-ui/react';
import './index.css';
export const GridButton = ({
  id,
  handleOnClick,
  handleMouseOver,
  handleMouseOut,
  imgSrc,
}) => (
  <Button
    bg="blue.500"
    className="Grid-item"
    id={`GridNumber-${id}`}
    onClick={() => handleOnClick(id)}
    onMouseOver={() => handleMouseOver(id)}
    onMouseOut={() => handleMouseOut(id)}
  >
    <img src={imgSrc} alt="Hit" className="fire" id={`fire-${id}`} />
    {id}
  </Button>
);
