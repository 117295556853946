import { useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  InputRightElement,
  InputGroup,
  Alert,
  AlertTitle,
  AlertIcon,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import './index.css';
import { onlyEmail } from '../../utils/functions';
import Axios from 'axios';
export function Login({ history }) {
  const [showPassword, setShowPassword] = useState(false);

  const [inputE, setInputE] = useState('');
  const handleInputChangeE = e => setInputE(e.target.value);

  const [inputP, setInputP] = useState('');
  const handleInputChangeP = e => setInputP(e.target.value);

  const [isErrorAccount, setIsErrorAccount] = useState('none');

  const login = async (email, password) => {
    Axios({
      method: 'POST',
      data: {
        email,
        password,
      },
      withCredentials: true,
      headers: {"Access-Control-Allow-Origin": "*"},
      url: `${process.env.REACT_APP_HTTP_S}://${process.env.REACT_APP_IP_ADDRESS}/login`,
    })
      .then(() => {
        setIsErrorAccount('loggedIn');
        history('/main');
      })
      .catch(() => setIsErrorAccount('WrongEmailOrPassword'));
  };

  const isErrorE = inp => {
    return inp === '' || !onlyEmail(inp);
  };
  const isErrorP = inp => {
    return inp === '';
  };
  return (
    <Flex
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8}>
        <Stack align={'center'}>
          <Heading fontSize={'3xl'}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            {isErrorAccount === 'loggedIn' ? (
              <Alert status="success">
                <AlertIcon />
                <AlertTitle>Successfully logged in.</AlertTitle>
              </Alert>
            ) : isErrorAccount === 'notFound' ? (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Account not found.</AlertTitle>
              </Alert>
            ) : isErrorAccount === 'WrongEmailOrPassword' ? (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Invalid email or password.</AlertTitle>
              </Alert>
            ) : null}
            <FormControl
              id="email"
              isRequired={isErrorE(inputE)}
              isInvalid={isErrorE(inputE)}
            >
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                onChange={handleInputChangeE}
                value={inputE}
              />
            </FormControl>
            <FormControl
              id="password"
              isRequired={isErrorP(inputP)}
              isInvalid={isErrorP(inputP)}
            >
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleInputChangeP}
                  value={inputP}
                />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword(showPassword => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}
              >
                <Link color={'blue.400'} marginLeft="10.625rem">
                  Forgot password?
                </Link>
              </Stack>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={() => login(inputE, inputP)}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
